import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LineFrequency } from '../../store/slices/lineFrequencySlice';
import { Port } from '../../store/slices/portsSlice';
import { Location } from '../../store/slices/locationsSlice';
import { Falsy } from '../../types/types';
import { Vessel } from '../../store/slices/vesselsSlice';

export type VoyagePartTemplate = {
    id?: number | Falsy,
    ets?: number | Falsy,
    ete?: number | Falsy,
    berth?: Location | Falsy,
    port?: Port | Falsy,
}

export type VoyagePartGroupTemplate = {
    voyagePartsTemplates: Array<VoyagePartTemplate> | Array<any>
}

export type VesselPosition = {
    position: number,
    vessel: Vessel | Falsy
}

export type ServiceLine = {
    id?: number | Falsy,
    name?: string | null,
    duration?: number | null,
    frequency?: LineFrequency | null,
    // start datetime of service line
    refStartDate?: number | null,
    // startTimeframe of deploy line
    startTimeframe? : number | null,
    // endTimeframe of deploylines
    endTimeframe?: number | null,
    voyagePartGroupTemplate?: VoyagePartGroupTemplate | null,
    voyagePartGroupTemplateId?: number | Falsy,
    vesselsPositionList?: Array<VesselPosition> | null,
    voyageNumberPattern?: string,
    isVNPatternValid?: boolean,
    public?: boolean
}

const initVoyagePartTemplate: VoyagePartTemplate = {
    id: null,
    ets: null,
    ete: null,
    berth: null,
    port: null
};

const initialState: ServiceLine = {
    id: null,
    name: '',
    duration: null,
    frequency: null,
    refStartDate: null,
    startTimeframe: null,
    endTimeframe: null,
    voyagePartGroupTemplate: null,
    voyagePartGroupTemplateId: null,
    vesselsPositionList: null,
    voyageNumberPattern: '',
    isVNPatternValid: true,
    public: false
};

export const serviceLineWizardSlice = createSlice({
    name: 'serviceLine',
    initialState: initialState,
    reducers: {
        updateServiceLine: (state, action: PayloadAction<ServiceLine>) => {
            // If not necessary, do not pass null or undefined via action.payload because it will overwrite
            Object.assign(state, action.payload);
        },
        addVoyagePartTemplate: (state) => {
            // If voyagePartGroupTemplate is not null, push initVoyagePartTemplate to voyagePartsTemplates
            // else set the initial value to voyagePartGroupTemplate.voyagePartsTemplates
            if (state.voyagePartGroupTemplate) {
                state.voyagePartGroupTemplate.voyagePartsTemplates.push(initVoyagePartTemplate);
            } else {
                state.voyagePartGroupTemplate = { voyagePartsTemplates: [initVoyagePartTemplate] };
            }
        },
        removeVoyagePartTemplate: (state, action: PayloadAction<number>) => {
            // action.payload is the index of voyagePartsTemplate we want to remove
            if (action.payload > -1 && state.voyagePartGroupTemplate?.voyagePartsTemplates) {
                state.voyagePartGroupTemplate?.voyagePartsTemplates.splice(action.payload, 1);
            }
        },
        updateVoyagePartTemplate: (
            state,
            action: PayloadAction<{ index: number, newValue: VoyagePartTemplate }>
        ) => {
            const index = action.payload.index;
            if (index > -1 && state.voyagePartGroupTemplate?.voyagePartsTemplates?.[index]) {
                /// When changing port, we have to set berth to null
                Object.assign(state.voyagePartGroupTemplate.voyagePartsTemplates[index], action.payload.newValue);
            }
        },
        updateVesselPosition: (state, action: PayloadAction<{ index: number, newValue: Vessel | Falsy }>) => {
            const index = action.payload.index;
            if (index > -1 && state.vesselsPositionList?.[index]) {
                Object.assign(state.vesselsPositionList[index], 
                    {
                        vessel: action.payload.newValue,
                        position: state.vesselsPositionList[index].position
                    }
                );
            }
        },
        resetServiceLine: (state) => {
            Object.assign(state, initialState);
        }
    }
});

export const {
    updateServiceLine,
    addVoyagePartTemplate,
    removeVoyagePartTemplate,
    updateVoyagePartTemplate,
    updateVesselPosition,
    resetServiceLine
} = serviceLineWizardSlice.actions;

export default serviceLineWizardSlice.reducer;
