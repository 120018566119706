import { useState } from 'react';
import { APIQueryParams } from '../types/types';
import { POLLING_INTERVAL } from './constants';
import { UseQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks';

// This is a type we implemented for subscriptionOptions, it could develope during the time
type SubscriptionOptions = {
    pollingInterval?: number | undefined,
    skip?: boolean,
    refetchOnMountOrArgChange?: boolean,
}

export const useRetryFetchQuery = (
    query: UseQuery<any>, 
    queryParams?: APIQueryParams, 
    subscriptionOptions?: SubscriptionOptions
) : any => {
    const [isResponseWithError, setIsResponseWithError] = useState(false);
    const options = Object.assign(
        {}, 
        subscriptionOptions, 
        {
            pollingInterval: isResponseWithError ? POLLING_INTERVAL : undefined
        }
    );
    const { data, isFetching, isError, refetch } = query(queryParams, options);
    if(isError && !isResponseWithError) {
        setIsResponseWithError(isError);
    } else if (!isError && isResponseWithError) {
        setIsResponseWithError(false);
    }
    return { data, isFetching, refetch};
};
