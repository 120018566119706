import {PaletteMode, ThemeOptions} from '@mui/material';

declare module '@mui/system' {
    interface Theme {
        length: {
            limitedSpan: {
                width: string | number;
                maxWidth: string | number;
            }
        };
    }
    // allow configuration using `createTheme`
    interface ThemeOptions {
        length?: {
            limitedSpan?: {
                width?: string | number;
                maxWidth?: string | number;
            }
        };
    }
}

export const getDesignTokens = (mode: PaletteMode): ThemeOptions => ({
    palette: {
        mode: mode,
        primary: {
            main: '#19325c',
            dark: '#000a32',
            light: '#485b89',
        },
        secondary: {
            main: '#ff9c1f',
            dark: '#c66d00',
            light: '#ffcd55',
        },
    },
    components: {
        MuiButton: {
            defaultProps: {
                variant: 'outlined',
                size: 'small'
            },
        },
        MuiTextField: {
            defaultProps: {
                size: 'small',
                sx: {
                    width: 200
                }
            },
        },
        MuiPaper: {
            defaultProps: {
                sx: { 
                    m: 2, p: 4 
                }, 
                elevation: 3,
            }
        },
        MuiToggleButton: {
            defaultProps: { 
                size: 'small'
            },
        },
    },
    spacing: 4, // The default value is 4px. ex: theme.spacing(2), will return 8px.
    length: {
        limitedSpan: {
            width: '100%',
            maxWidth: 150,
        }
    }
});
