import React, { FC } from 'react';
import { styled } from '@mui/system';
import ButtonBase from '@mui/material/ButtonBase';
import Avatar from '@mui/material/Avatar';
import { useTheme } from '@mui/material/styles';
import LimitedSpan from '../LimitedSpan/LimitedSpan';

type AvatarProps = {
    onClick: (event: React.MouseEvent<HTMLElement>) => void, // any function
    name: string,
    imageUrl: string | null
}

const MyButtonBase = styled(ButtonBase)({
    color: 'white',
});

const ClickableAvatar: FC<AvatarProps> = ({onClick, name, imageUrl}) => {
    const theme = useTheme();

    return (
        <MyButtonBase
            onClick={onClick}
        >
            <Avatar alt={name} src={imageUrl || ''} 
                style={{marginRight: theme.spacing(2)}}
            />
            <LimitedSpan sx={{ maxWidth: 100, marginLeft: 2, width: 'calc(100% - 40px)' }}>
                {name}
            </LimitedSpan>
        </MyButtonBase>
    );
};

export default ClickableAvatar;
