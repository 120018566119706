import { api } from './apiSlice';
import { APIQueryParams, ListResponse } from '../../types/types';

export interface LineFrequency {
    id: number,
    name: string,
    frequency_hours: number
}

const url = '/line_frequencies/';

export const { useFetchLineFrequenciesQuery } = api.injectEndpoints({
    endpoints: build => ({
        fetchLineFrequencies: build.query<ListResponse<LineFrequency>, APIQueryParams>({
            query(params) {
                return `${url}?offset=${(Number(params.page) - 1) * (params.size ? params.size : 25)}&limit=${params.size ? params.size : 25}`;
            }
        }),
    }),
});
