import { configureStore } from '@reduxjs/toolkit';
import { api } from './slices/apiSlice';
import serviceLineWizardReducer from '../views/ServiceLineWizard/serviceLineWizardSlice';
import alertDialogSliceReducer from '../components/AlertDialog/AlertDialogSlice';
import alertSnackbarSliceReducer from '../components/AlertSnackbar/AlertSnackbarSlice';
import userDataApiStatusReducer from './slices/userDataSlice';

export const store = configureStore({
    reducer: {
        [api.reducerPath]: api.reducer,
        serviceLineWizard: serviceLineWizardReducer,
        alertDialog: alertDialogSliceReducer,
        alertSnackbar: alertSnackbarSliceReducer,
        userDataApiStatus: userDataApiStatusReducer
    },
    middleware: (getDefaultMiddleware) => {
        return getDefaultMiddleware().concat(api.middleware);
    },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
