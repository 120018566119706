import { FC, useState, SyntheticEvent } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Typography from '@mui/material/Typography';
import debounce from 'lodash/debounce';
import Box from '@mui/system/Box';
import Highlighter from 'react-highlight-words';
import { useFetchVesselsQuery, VesselsFilter, Vessel, 
    filter2String as convert } from '../../store/slices/vesselsSlice';
import { DEBOUNCE_DELAY } from '../../utils/constants';
import { Falsy } from '../../types/types'; 
import { useRetryFetchQuery } from '../../utils/hooks';

type Props = {
    onChange?: (vessel: Vessel | Falsy) => void,
    id?: string,
    label?: string,
    vessel?: Vessel | null,
    size?: 'small' | 'medium' | undefined

}

const VesselSelector: FC<Props> = ({ onChange, id, label, vessel, size }: Props) => {
    const selectedVessel: Vessel | Falsy = vessel;
    const actualSize: 'small' | 'medium' | undefined = size ?? 'small';
    const newLabel = label ? label : 'Vessel';
    const [filter, setFilter] = useState<VesselsFilter>({});
    const { data, isFetching } = useRetryFetchQuery(
        useFetchVesselsQuery,
        { page: 1, size: 30, filter: convert(filter) },
    );
    const onInputChange = (event: React.SyntheticEvent, newInputValue: string) => {
        setFilter({ name: newInputValue });
    };
    const debouncedOnInputChange = debounce(onInputChange, DEBOUNCE_DELAY);

    return (
        <Autocomplete
            value={selectedVessel}
            onChange={(event: SyntheticEvent, newValue: Vessel | Falsy) => {
                if (onChange) {
                    onChange(newValue);
                }
            }}
            onInputChange={debouncedOnInputChange}
            options={data?.results ?? []}
            renderInput={(params) => <TextField {...params} label={newLabel} />}
            getOptionLabel={(option) => option.name || ''}
            autoHighlight
            loading={isFetching}
            size={actualSize}
            renderOption={(props, option) => (
                <Box component='li' {...props} sx={{ display: 'flex', flexDirection: 'column' }} key={option.id}>
                    <Typography variant='subtitle2' gutterBottom component='div' align='left' sx={{ width: '100%' }}>
                        <Highlighter searchWords={[filter.name ?? '']} textToHighlight={option.name || ''} />
                    </Typography>
                    <Typography variant='caption' gutterBottom component='div' align='left' sx={{ width: '100%' }}>
                        IMO: <Box component="span" fontWeight='fontWeightMedium'>{option.imo}</Box>&nbsp;
                        MMSI: <Box component="span" fontWeight='fontWeightMedium'>{option.mmsi}</Box>
                    </Typography>
                </Box>
            )}
            isOptionEqualToValue={(option: Vessel, value: Vessel) => {
                return (value && option.id === value.id);
            }}
        />
    );
};

export default VesselSelector;
