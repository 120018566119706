import { api } from './apiSlice';
import { Falsy, APIQueryParams } from '../../types/types';

export interface Port {
    id: number | Falsy,
    name: string | Falsy,
    locode: string | Falsy,
    lat: number | Falsy,
    lon: number | Falsy,
    port_polygon: string | Falsy,
}

export interface Location {
    id: string | Falsy,
    index: number | Falsy,
    type: string | Falsy,
    rank: number | Falsy,
    location: string | Falsy,
    location_type: string | Falsy,
    direction: number | Falsy,
    berth_type?: string | Falsy,
    name: string | Falsy,
    nr: string | Falsy,
    owner: string | Falsy,
    port: Port,
    centroid: string | Falsy,
    original_name: string | Falsy,
    code: string | Falsy
}

export interface Locations {
    count: number,
    next: string,
    previous: string,
    results: Array<Location>,
}

export interface LocationFilter {
    type?: string;
    port_id?: number,
    locode?: string,
    search?: string,
}

export const { useFetchLocationsQuery } = api.injectEndpoints({
    endpoints: build => ({
        fetchLocations: build.query<Locations, APIQueryParams>({
            query(params) {

                const offset = `${params.page && params.size ? `offset=${(Number(params.page) - 1) * (params.size ? params.size : 25)}` : ''}`;
                const limit = `&${params.size ? `limit=${params.size}` : ''}`;
                return `/locations/lookup/?${offset}${limit}${params.filter}`;
            }
        }),
    }),
});
