import { FC, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import ModalCircularProgress from '../../components/ModalCircularProgress/ModalCircularProgress';
import { useAppSelector, useAppDispatch } from '../../store/hooks';
import { updateServiceLine, updateVoyagePartTemplate, resetServiceLine, } from './serviceLineWizardSlice';
import FirstStep from './ServiceLineWizardStep1';
import SecondStep from './RoundTripDescription';
import { paths } from '../../App';
import Map from './Summary';
import VesselsList from './VesselsList';
import { secureFetchWithStatus, doesServiceLineHaveEmptyFields } from '../../utils/utils';
import { openAlertDialog } from '../../components/AlertDialog/AlertDialogSlice';
import { fetchServiceLine } from '../GenerateLine';
import PublicButton from '../../components/PublicButton/PublicButton';

const ServiceLineWizard: FC = () => {
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useAppDispatch();
    const { t } = useTranslation('common');
    const serviceLineWizard = useAppSelector((state) => state.serviceLineWizard);
    const { name, duration, frequency, refStartDate, vesselsPositionList, 
        voyageNumberPattern } = serviceLineWizard;
    const haveEmptyFields = doesServiceLineHaveEmptyFields(serviceLineWizard);
    const { id } = useParams<{ id: string }>();


    useEffect(() => {
        if (id) {
            fetchServiceLine(id, dispatch, history);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const checkServiceNameExists = async () => {
        const isPUTMethod = Boolean(serviceLineWizard?.id && serviceLineWizard?.voyagePartGroupTemplateId);
        const checkNameRet = await secureFetchWithStatus(`/api/service_lines/?name_equals=${name}`);
        if (checkNameRet.http_status === 200) {
            if (checkNameRet.result.results.length > 0) {
                if (!isPUTMethod || checkNameRet.result.results[0].id !== serviceLineWizard?.id) {
                    dispatch(openAlertDialog({
                        open: true,
                        content: t('duplicate_service_line_name_error_message'),
                        buttons: [
                            {
                                title: t('ok'),
                                variant: 'contained',
                            }
                        ]
                    }));
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        }
    };

    const createServiceline = async () => {
        const isPUTMethod = Boolean(serviceLineWizard?.id && serviceLineWizard?.voyagePartGroupTemplateId);
        //Check if the service lne name already exists
        const nameAlreadyExists = await checkServiceNameExists();
        let requestsWereOk = false;
        if (!nameAlreadyExists) {
            // post VPGtemplate
            setIsLoading(true);
            const VPGTemplated = isPUTMethod ? serviceLineWizard?.voyagePartGroupTemplateId + '/' : '';
            const serviceLineId = isPUTMethod ? serviceLineWizard?.id + '/' : '';
            const ret = await secureFetchWithStatus(`/api/voyage_part_group_templates/${VPGTemplated}`,
                {
                    type: 'VOYAGE',
                    name: name
                },
                isPUTMethod ? 'PUT' : 'POST'
            );
            if (ret.http_status === 200 || ret.http_status === 201) {
                // Update reducer with the return id
                if (ret.result.id) {
                    dispatch(updateServiceLine({ voyagePartGroupTemplateId: ret.result.id }));
                }
                // post serviceLine
                const srRet = await secureFetchWithStatus(`/api/service_lines/${serviceLineId}`,
                    {
                        name: name,
                        rd_duration: duration ? duration * 24 * 60 * 60 : 0, // duration in seconds
                        frequency: frequency?.id,
                        start_date: refStartDate,
                        template: ret.result.id,
                        voyage_number_pattern: voyageNumberPattern,
                        public: serviceLineWizard.public
                    },
                    isPUTMethod ? 'PUT' : 'POST'
                );
                // Update reducer with the return id
                if (srRet.http_status === 201 || srRet.http_status === 200) {
                    if (srRet.result.id) {
                        dispatch(updateServiceLine({ id: srRet.result.id }));
                        history.push(`${paths.service_line_wizard}/${srRet.result.id}`);
                    }
                    const vesselPositionsRet = await secureFetchWithStatus('/api/vessels_to_deploy/',
                        {
                            service_line_id: isPUTMethod ? serviceLineWizard.id : srRet.result.id,
                            vessel_positions: vesselsPositionList?.map(vp => (
                                {
                                    vessel_id: vp?.vessel?.id,
                                    position: vp?.position
                                }
                            ))
                        },
                        'POST'
                    );
                    if (vesselPositionsRet.http_status === 201) {
                        requestsWereOk = true;
                    } else if (vesselPositionsRet.http_status === 400) {
                        dispatch(openAlertDialog({
                            open: true,
                            content: vesselPositionsRet.result.result,
                            buttons: [
                                {
                                    title: t('ok'),
                                    variant: 'contained',
                                }
                            ]
                        }));
                    }
                }
                // POST VoyagePartTemplates
                serviceLineWizard.voyagePartGroupTemplate?.voyagePartsTemplates?.forEach(async (VPTemplate, index) => {
                    const VPTempId = VPTemplate?.id ? VPTemplate?.id + '/' : '';
                    const vptempRet = await secureFetchWithStatus(`/api/voyage_part_templates/${VPTempId}`,
                        {
                            type: 'STATIC',
                            start_location: VPTemplate.berth?.id,
                            end_location: VPTemplate.berth?.id,
                            ets: VPTemplate.ets,
                            ete: VPTemplate.ete,
                            voyage_group_id: ret.result.id,
                            scn: 0,
                            port_id: VPTemplate?.port.id,
                        },
                        VPTemplate.id ? 'PUT' : 'POST'
                    );
                    if (vptempRet.http_status === 200 || vptempRet.http_status === 201) {
                        dispatch(updateVoyagePartTemplate({ index, newValue: { id: vptempRet.result.id } }));
                    }
                });
            }
            setIsLoading(false);
            if (requestsWereOk) {
                history.push(paths.service_lines_list);
            }
        }
    };

    return (
        <Box sx={{ pl: 4, pr: 4 }}>
            <Stack direction="row" spacing={2}>
                <Typography variant={'h4'} component={'h2'} sx={{ mb: 4, mt: 4 }}>
                    {
                        serviceLineWizard.id ?
                            t('edit_service_line')
                            : t('create_service_line')
                    }
                </Typography>
                <PublicButton 
                    lineID={serviceLineWizard.id}
                    isPublic={Boolean(serviceLineWizard.public)}
                    onChange={(newPublicState) => dispatch(updateServiceLine({ public: newPublicState}))}
                />
            </Stack>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <FirstStep />
                    <VesselsList />
                </Grid>
                <Grid item xs={6}>
                    <Map />
                </Grid>
                <Grid item xs={12}>
                    <SecondStep />
                </Grid>
                <Grid container>
                    <Grid item xs={1}> </Grid>
                    <Grid item xs={5}>
                        <Button color='error'
                            onClick={() => {
                                dispatch(resetServiceLine());
                                history.push(paths.service_lines_list);
                            }}
                        >
                            {t('cancel')}
                        </Button>
                    </Grid>
                    <Grid item xs={5}>
                        <Stack spacing={2} direction='row' justifyContent='flex-end'
                            title={haveEmptyFields ? t('please_fill_all_fields') : ''}
                        >
                            <Button variant='contained'
                                onClick={() => {
                                    // save changes into database
                                    createServiceline();
                                }}
                                disabled={haveEmptyFields}
                            >
                                {t('Save')}  Service Line
                            </Button>
                        </Stack>
                    </Grid>
                    <Grid item xs={1}> </Grid>
                </Grid>
                <ModalCircularProgress open={isLoading} />
            </Grid>
        </Box>
    );
};

export default ServiceLineWizard;
