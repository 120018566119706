import { styled } from '@mui/system';
import Box from '@mui/material/Box';

const LimitedSpan = styled(Box)(({ theme }) => ({
    width: theme.length.limitedSpan.width,
    maxWidth: theme.length.limitedSpan.maxWidth,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
}));

export default LimitedSpan;
