import { FC, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Typography from '@mui/material/Typography';
import debounce from 'lodash/debounce';
import Box from '@mui/system/Box';
import Highlighter from 'react-highlight-words';
import { filter2String as convert } from '../../utils/utils';
import { DEBOUNCE_DELAY, TEXTFIELD_MAXWIDTH } from '../../utils/constants';
import { Falsy } from '../../types/types';
import { useFetchLocationsQuery, Location } from '../../store/slices/locationsSlice';
import { useRetryFetchQuery } from '../../utils/hooks';

type Props = {
    onChange?: (location: Location | Falsy, id?: number) => void,
    // id is index of item in reducer
    id?: number,
    location?: Location | Falsy,
    // show berths or terminals in specific port or all ports.
    port?: number,
    // type can be 'berth', 'terminal', 'berth,terminal'
    type: string,
    label: string,
    rootStyle?: any,
}

// LocationSelector can be BerthSelector or TerminalSelector or both
const LocationSelector: FC<Props> = ({ onChange, id, location, port, type, label, rootStyle }: Props) => {
    const [searchedText, setSearchedText] = useState('');
    const [value, setValue] = useState(location);
    let query;
    if (port !== undefined) {
        query = { type, port_id: port, search: searchedText };
    } else {
        query = { type, search: searchedText };
    }
    const { data = { results: [] }, isFetching } = useRetryFetchQuery(
        useFetchLocationsQuery,
        { page: 1, size: 30, filter: convert(query) }
    );
    const onInputChange = (event: React.SyntheticEvent, newInputValue: string) => {
        setSearchedText(newInputValue);
    };
    const debouncedOnInputChange = debounce(onInputChange, DEBOUNCE_DELAY);

    return (
        <Autocomplete
            disablePortal
            value={value}
            onChange={(event: any, newValue: Location | Falsy) => {
                setValue(newValue);
                if (onChange) {
                    onChange(newValue, id);
                }
            }}
            onInputChange={debouncedOnInputChange}
            options={data?.results}
            renderInput={(params) => <TextField {...params} label={label} 
                sx={{width: '100%', maxWidth: TEXTFIELD_MAXWIDTH}}
            />}
            getOptionLabel={(option) => option.name || ''}
            filterOptions={(options, state) =>  options}
            autoHighlight
            loading={isFetching}
            size={'small'}
            renderOption={(props, option) => (
                <Box component='li' {...props} sx={{ display: 'flex', flexDirection: 'column' }} key={option.id}>
                    <Typography variant='subtitle2' gutterBottom component='div' align='left' sx={{ width: '100%' }}>
                        <Highlighter searchWords={[searchedText]} textToHighlight={option.name || option?.code || option.port?.locode || ''} />
                    </Typography>
                    <Typography variant='caption' gutterBottom component='div' align='left' sx={{ width: '100%' }}>
                        Port: <Box component="span" fontWeight='fontWeightMedium'>
                            <Highlighter searchWords={[searchedText]} textToHighlight={option.port?.name ?? ''} /> &nbsp;
                            (<Highlighter searchWords={[searchedText]} textToHighlight={option.port?.locode ?? ''} />)
                        </Box>
                        <br/>
                        Locode: <Box component="span" fontWeight='fontWeightMedium'>
                            <Highlighter searchWords={[searchedText]} textToHighlight={option?.code || ''} />
                        </Box>
                    </Typography>
                </Box>
            )}
            sx={rootStyle}
        />
    );
};

export default LocationSelector;
