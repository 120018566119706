import React, { FC, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';
import DateTimePicker from '@mui/lab/DateTimePicker';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { DateTime } from 'luxon';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import PortSelector from '../../components/PortSelector/PortSelector';
import { Port } from '../../store/slices/portsSlice';
import { Location } from '../../store/slices/locationsSlice';
import { Falsy } from '../../types/types';
import { useAppDispatch } from '../../store/hooks';
import { updateVoyagePartTemplate, removeVoyagePartTemplate, updateServiceLine } from './serviceLineWizardSlice';
import LocationSelector from '../../components/LocationSelector/LocationSelector';
import { DATETIME_FORMAT, TEXTFIELD_MAXWIDTH, WEEKDAYS, WEEKDAYS_ABBR } from '../../utils/constants';
import DurationPicker from '../../components/DurationPicker/DurationPicker';
import { secureFetchWithStatus } from '../../utils/utils';


type Props = {
    index: number, // index of item in VoyagePartTemplateGroup,
    port: Port | Falsy,
    ets: number | Falsy,
    ete: number | Falsy,
    berth: Location | Falsy,
    minDate: DateTime | null,
    maxDate: DateTime | null,
    timestamps: Array<number | Falsy>,
    id?: number
}

const RoundTripDescriptionItem: FC<Props> = ({ index, port, ets, ete, berth, timestamps, minDate, maxDate, id }) => {

    const [timezone, setTimezone] = useState<string>(port?.timezone ?? '');
    const dispatch = useAppDispatch();
    const onPortChange = (port: Port | Falsy) => {
        setTimezone(port?.timezone ?? '');
        dispatch(updateVoyagePartTemplate({
            index,
            newValue: { port, berth: null }
        }));
    };
    const onBerthChange = (berth: Location | Falsy) => {
        dispatch(updateVoyagePartTemplate({
            index,
            newValue: { berth }
        }));
    };
    const onRemoveClick = async () => {
        if(id) {
            const VPTRet = await secureFetchWithStatus(`api/voyage_part_templates/${id}/`,
                {},
                'DELETE'
            );
            if(VPTRet.http_status === 204 || VPTRet.http_status === 200) {
                dispatch(removeVoyagePartTemplate(index));
            }
        } else {
            dispatch(removeVoyagePartTemplate(index));
        }
    };
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));
    const isExtraLargeScreen = useMediaQuery(theme.breakpoints.up('xl'));
    const WEEKDAYS_ARR = isExtraLargeScreen ? WEEKDAYS : WEEKDAYS_ABBR;
    return (
        <Grid container spacing={1} sx={{ ml: 0 }}>
            <Grid item md lg xl>
                <PortSelector
                    onChange={onPortChange}
                    port={port}
                    rootStyle={{ display: 'flex', justifyContent: 'center' }}
                />
            </Grid>
            <Grid item md lg xl>
                <LocationSelector
                    onChange={onBerthChange}
                    port={port?.id}
                    type='berth'
                    label='Berth'
                    location={berth}
                    rootStyle={{ display: 'flex', justifyContent: 'center' }}
                />
            </Grid>
            <Grid item md lg xl sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                title={isSmallScreen && ets ? WEEKDAYS[DateTime.fromSeconds(ets).setZone(timezone).weekday - 1] : ''}
            >
                {!isSmallScreen ?
                    <Typography>
                        {ets ? WEEKDAYS_ARR[DateTime.fromSeconds(ets).setZone(timezone).weekday - 1] : ''}
                    </Typography> :
                    null
                }
                <DateTimePicker
                    onChange={(dateTime) => {
                        if (dateTime) {
                            dispatch(updateVoyagePartTemplate({
                                index,
                                newValue: { ets: dateTime.toSeconds() }
                            }));
                            // update reference start date as well
                            if (index === 0) {
                                dispatch(updateServiceLine({ refStartDate: dateTime.toSeconds() }));
                            }
                        }
                    }}
                    value={ets ? DateTime.fromSeconds(ets).setZone(timezone) : ''} //TODO: what to put in setZone if the port.timezone is not defined
                    label={'Berth Arrival'}
                    inputFormat={DATETIME_FORMAT}
                    renderInput={(params: any) =>
                        <TextField {...params} sx={{ width: '100%', maxWidth: TEXTFIELD_MAXWIDTH }} />
                    }
                    minDateTime={timestamps?.[index * 2 - 1] ? DateTime.fromSeconds(timestamps[index * 2 - 1] ?? 0) 
                        : index > 0 ? minDate : null} // If index is 0, there should not be any min date
                    maxDate={maxDate}
                />
            </Grid>
            <Grid item md lg xl sx={{ display: 'flex', justifyContent: 'center' }}>
                <DurationPicker
                    duration={
                        ete && ets ?
                            Number.parseInt(DateTime.fromSeconds(ete).diff(DateTime.fromSeconds(ets), ['seconds']).seconds.toFixed(0))
                            :
                            undefined}
                    onChange={(duration) => {
                        if (ets) {
                            dispatch(updateVoyagePartTemplate({
                                index: index,
                                newValue: { ete: DateTime.fromSeconds(ets).plus({ seconds: duration }).toSeconds() }
                            }));
                        }
                    }}
                    rootStyle={{ width: '50%', minWidth: 120 }}
                />
            </Grid>
            <Grid item md lg xl sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                title={isSmallScreen && ete ? WEEKDAYS[DateTime.fromSeconds(ete).setZone(timezone).weekday - 1] : ''}
            >
                {!isSmallScreen ?
                    <Typography>
                        {ete ? WEEKDAYS_ARR[DateTime.fromSeconds(ete).setZone(timezone).weekday - 1] : ''}
                    </Typography> :
                    null
                }
                <DateTimePicker
                    onChange={(dateTime) => {
                        if (dateTime) {
                            dispatch(updateVoyagePartTemplate({
                                index: index,
                                newValue: { ete: dateTime.toSeconds() }
                            }));
                        }
                    }}
                    value={ete ? DateTime.fromSeconds(ete).setZone(timezone) : ''}//TODO: what to put in setZone if the port.timezone is not defined
                    label={'Berth Departure'}
                    inputFormat={DATETIME_FORMAT}
                    renderInput={(params: any) =>
                        <TextField {...params} sx={{ width: '100%', maxWidth: TEXTFIELD_MAXWIDTH }} />
                    }
                    minDateTime={timestamps?.[index * 2] ? DateTime.fromSeconds(timestamps[index * 2] ?? 0) : null}
                    maxDate={maxDate}
                />
            </Grid>
            <Grid item md lg xl >
                <Stack justifyContent='center' alignItems='center' direction='row' sx={{ height: '100%' }}>
                    <Button variant='outlined' color='primary'
                        sx={{ ml: 6, mr: 2 }}
                        disabled
                    >
                        Details
                    </Button>
                    <Button variant='outlined' color='error'
                        onClick={onRemoveClick}
                    >Remove</Button>
                </Stack>
            </Grid>
        </Grid>
    );
};

export default RoundTripDescriptionItem;
