import { api } from './apiSlice';
import { UserData, ListResponse, ApiInstruction } from '../../types/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const initialUserDataState: UserData = {
    id: -1,
    username: '',
    first_name: '',
    last_name: '',
    email: '',
    rights: [
    ],
    address: '',
    telephone_number: null,
    role: null,
    avatar_url: '',
    header: '',
    organization_admin: false,
    super_admin: false,
    active: true,
    organisation: {
        id: null,
        name: '',
        web: '',
        avatar_url: '',
        address: '',
        email: '',
        phone: '',
        avatar_img: ''
    },
    avatar_img: null // For now, we keep it empty, but think about how to load users photos
};

type ApiStatusState = {
    statusCode: number,
    responseLength: number
};

const initApiStatusState: ApiStatusState = {
    statusCode: 0,
    responseLength: 0
};

const userDataApiStatusSlice = createSlice({
    name: 'userDataApiStatus',
    initialState: initApiStatusState,
    reducers: {
        setStatus: (state, action: PayloadAction<ApiStatusState>) => {
            Object.assign(state, action.payload);
        },
    }
});

const { setStatus } = userDataApiStatusSlice.actions;
export default userDataApiStatusSlice.reducer;


const userDataApiSlice = api.injectEndpoints({
    endpoints: build => ({
        fetchUserData: build.query<ListResponse<UserData>, ApiInstruction | void>({
            async queryFn(arg, queryApi, extraOptions, fetchWithBQ) {
                // Query params are stored in "args". We can easily add them to the url 
                const result: any = await fetchWithBQ('user_info/');
                const { dispatch } = queryApi;

                if (result.error) {
                    return { error: result.error };
                }
                if (result?.data?.results.length === 0) {
                    dispatch(setStatus({
                        statusCode: 403, //UnAuthN (Unauthenticated user)
                        responseLength: 0
                    }));
                }
                else {
                    dispatch(setStatus({
                        statusCode: 200, //UnAuthN (Unauthenticated user)
                        responseLength: result?.data?.results.length
                    }));
                }
                return { data: result.data as ListResponse<UserData> };
            }
        }),
    }),
});

export const { useFetchUserDataQuery } = userDataApiSlice;
