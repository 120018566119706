import { api } from './apiSlice';
import { Falsy, APIQueryParams } from '../../types/types';
import { LineFrequency } from '../../store/slices/lineFrequencySlice';

export interface ServiceLineInfo {
    id: number,
    name: string,
    org: number,
    user: number,
    rd_duration: string,
    frequency: LineFrequency | null,
    start_date: Date,
    template: number,
    created: Date,
    updated: Date,
    voyage_number_pattern: string,
    public: boolean
}

export interface ServiceLines {
    count: number,
    next: string,
    previous: string,
    results: Array<ServiceLineInfo>
}

export interface ServiceLineFilter {
    name?: string,
}

export const filter2String = (filter?: ServiceLineFilter | Falsy) : string => {
    const res = [];
    if (filter?.name) {
        res.push('name_contains=' + filter.name);
    }
    if (res.length > 0) {
        return '&' + res.join('&');
    } else {
        return '';
    }
};

export const { useFetchServiceLinesQuery } = api.injectEndpoints({
    endpoints: build => ({
        fetchServiceLines: build.query<ServiceLines, APIQueryParams>({
            
            query(params) {
                return `/service_lines/?offset=${(Number(params.page) - 1) * (params.size ? params.size : 25)}&limit=${params.size ? params.size : 25}${params.filter}`;
            },
        }),
        
    }),
});
