import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ButtonProps } from '@mui/material/Button';
import { Falsy } from '../../types/types';

type AlertDialog = {
    open: boolean,
    title?: string | Falsy,
    content?: string | Falsy,
    buttons?: Array<ButtonProps & {title: string}>
}

const initialState = {
    open: false,
    title: '',
    content: '',
    buttons: new Array<ButtonProps & {title: string}>() //Array constructor
};

export const alertDialogSlice = createSlice({
    name: 'alertDialog',
    initialState: initialState,
    reducers: {
        openAlertDialog: (state, action: PayloadAction<AlertDialog>) => {
            Object.assign(state, action.payload);
        },
        closeAlertDialog: (state) => {
            Object.assign(state, initialState);
        }
    }
});

export const { openAlertDialog, closeAlertDialog } = alertDialogSlice.actions;

export default alertDialogSlice.reducer;
