import { FC } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useAppSelector, useAppDispatch } from '../../store/hooks';
import { closeAlertDialog } from './AlertDialogSlice';


const AlertDialog: FC = () => {
    const alertDialog = useAppSelector((state) => state.alertDialog);
    const dispatch = useAppDispatch();
    const { title, content, open } = alertDialog;

    return (
        <Dialog
            open={open}
        >
            {title ? 
                <DialogTitle>
                    {title}
                </DialogTitle>
                : null
            }
            <DialogContent>
                <DialogContentText>
                    {content}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                {
                    alertDialog.buttons?.map(button => 
                        <Button 
                            key={button?.title}
                            {...button}
                            onClick={
                                button.onClick ? 
                                    button.onClick : () => dispatch(closeAlertDialog())
                            }
                        >
                            {button?.title}
                        </Button>
                    )
                }
            </DialogActions>
        </Dialog>
    );
};

export default AlertDialog;
