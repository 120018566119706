import { FC, useState, useRef, useEffect } from 'react';
import ReactMapGL, {
    Source, Layer, Popup, LayerProps,
    WebMercatorViewport, MapEvent
} from 'react-map-gl';
import mapboxgl from 'mapbox-gl'; 
import 'mapbox-gl/dist/mapbox-gl.css';
import { useResizeDetector } from 'react-resize-detector';
import customMarker from '../../assets/img/custom-marker.png';
import { MapBoxMarkerInfo, LatLngExpression } from '../../types/types';

/* eslint-disable @typescript-eslint/no-var-requires */
// eslint-disable-next-line import/no-webpack-loader-syntax
(mapboxgl as any).workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

type Props = {
    center?: LatLngExpression,
    zoom?: number,
    markers?: MapBoxMarkerInfo[],
    style?: { width: number | string, height: number | string },
}

type Viewport = {
    width: number | string,
    height: number | string,
    latitude: number,
    longitude: number,
    zoom: number,
}

type PopupInfo = {
    longitude: number,
    latitude: number,
    description: string,
}

// default center is Hamburg, later move the value to configuration.
const Map: FC<Props> = ({
    center = { latitude: 53.551086, longitude: 9.993682 },
    zoom = 13,
    markers = [],
    style = { width: '100%', height: '100%' }
}) => {
    const mapRef = useRef<any>();
    // the following ref is for div container of ReactMapGL, the real width & height of map in pixel
    // are necessary for WebMercatorViewport by which the new viewport can be set smoothly after 'fitBounds'
    // is invoked when a marker is added to or removed from the map.
    const { width, height, ref } = useResizeDetector();
    const [viewport, setViewport] = useState<Viewport>({
        width: style.width,
        height: style.height,
        latitude: Array.isArray(center) ? center[1] : center.latitude,
        longitude: Array.isArray(center) ? center[0] : center.longitude,
        zoom,
    });
    const [popupInfo, setPopupInfo] = useState<PopupInfo | null>(null);

    // currently the best way to load a custom image for marker in react-map-gl
    useEffect(() => {
        const map = mapRef.current.getMap();
        map.loadImage(customMarker, (error: any, image: any) => {
            if (error) throw error;
            if (!map.hasImage('custom-marker')) map.addImage('custom-marker', image, { sdf: false });
        });
    }, [mapRef]);

    const layerStyle: LayerProps = {
        id: 'marker-layer',
        type: 'symbol',
        paint: {
            'text-color': '#ffffff',
        },
        layout: {
            // 'custom-marker' is the id of the custom icon image
            'icon-image': 'custom-marker',
            'icon-size': 1,
            // 'title' is from geojson properties.
            'text-field': ['get', 'title'],
            'text-size': 12,
            // with the following two options, custom markers will not disappear when they overlap each other
            // However, the text within custom marker will still disappear
            'icon-allow-overlap': true,
            'text-optional': true,
        }
    };
    const markersFeatures: any = markers.filter(marker => marker.name).map((marker, i) => ({
        type: 'Feature',
        geometry: {
            type: 'Point',
            coordinates: Array.isArray(marker.position) ? marker.position : [marker.position.lon, marker.position.lat],
        },
        properties: {
            title: `${i + 1} ${marker.name[0]}`,
            description: marker.name,
        }
    }));

    // calculate bounds of markers
    const applyToArray = (func: any, array: any) => func.apply(Math, array);
    useEffect(() => {
        if (mapRef && width && height) {
            const map = mapRef.current.getMap();
            // calculate bounds of markers when more than one markers are on map
            if (markersFeatures.length > 1) {
                const pointsLong = markersFeatures.map((feature: any) => feature.geometry.coordinates[0]);
                const pointsLat = markersFeatures.map((feature: any) => feature.geometry.coordinates[1]);
                const cornersLongLat: [[number, number], [number, number]] = [
                    [applyToArray(Math.min, pointsLong), applyToArray(Math.min, pointsLat)],
                    [applyToArray(Math.max, pointsLong), applyToArray(Math.max, pointsLat)]
                ];
                // use WebMercatorViewport to fit bounds of markers with padding 30 pixels
                // use returned new viewport to update viewport in state to avoid 'jumping'
                // when the map is dragged.
                const viewport = new WebMercatorViewport({
                    width: width ?? 1,
                    height: height ?? 1,
                }).fitBounds(cornersLongLat, { padding: 30 });
                setViewport((prevViewport) => ({ ...prevViewport, ...viewport }));
            } else if (markersFeatures.length === 1) {
                // fly to center of marker when there is only one marker on map
                map.flyTo({ center: markersFeatures[0].geometry.coordinates });
                const center = markersFeatures[0].geometry.coordinates;
                setViewport((prevViewport) => ({ ...prevViewport, longitude: center[0], latitude: center[1] }));
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [markersFeatures.length, width, height]);

    const markersGeoJSON: any = {
        type: 'FeatureCollection',
        features: markersFeatures,
    };
    const getCursor = ({ isHovering, isDragging }: { isHovering: boolean, isDragging: boolean }) => {
        return isDragging ? 'grabbing' : isHovering ? 'pointer' : 'default';
    };

    const onMarkerLayerClick = (evt: MapEvent) => {
        if (evt?.features?.[0]?.layer?.id === 'marker-layer') {
            const lngLat = evt?.lngLat;
            const description = evt?.features?.[0]?.properties?.description;
            setPopupInfo({ longitude: lngLat[0], latitude: lngLat[1], description });
        }
    };

    const onMapClick = (evt: MapEvent) => {
        onMarkerLayerClick(evt);
    };


    return (
        <div ref={ref} style={{ width: '100%', height: '100%' }}>
            <ReactMapGL
                ref={mapRef}
                mapStyle='mapbox://styles/mapbox/streets-v8'
                {...viewport}
                width={style.width}
                height={style.height}
                onViewportChange={(nextViewport: Viewport) => setViewport(nextViewport)}
                getCursor={getCursor}
                // make marker layer interactive, so that different cursor can be shown
                interactiveLayerIds={['marker-layer']}
                onClick={onMapClick}
            >
                {popupInfo && (<Popup
                    tipSize={5}
                    anchor='bottom'
                    longitude={popupInfo.longitude}
                    latitude={popupInfo.latitude}
                    closeOnClick={true}
                    onClose={setPopupInfo}
                >
                    <div>{popupInfo.description}</div>
                </Popup>
                )}
                <Source id='marker-source' type='geojson' data={markersGeoJSON}>
                    <Layer {...layerStyle} />
                </Source>
            </ReactMapGL>
        </div>
    );
};

export default Map;
