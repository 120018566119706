import { api } from './apiSlice';
import { APIQueryParams } from '../../types/types';

export interface Port {
    uuid?: string,
    id?: number,
    name?: string,
    country?: string,
    locode?: string,
    lookat?: string,
    created?: Date,
    updated?: Date,
    lat?: number,
    lon?: number,
    timezone?: string,
    polygon?: any,
    aliases?: Array<string>
}

export interface Ports {
    count: number,
    next: string,
    previous: string,
    results: Array<Port>
}

export interface PortFilter {
    uuid?: string;
    id?: string,
    name?: string,
    country?: string,
    aliases?: Array<string>
}

export function filter2String(filter?: PortFilter): string {
    const res = [];
    if (filter?.uuid) {
        res.push('uuid=' + filter?.uuid);
    }
    if (filter?.id) {
        res.push('id=' + filter?.id);
    }
    if (filter?.name) {
        res.push('name=' + filter?.name);
    }
    if (filter?.country) {
        res.push('country=' + filter?.country);
    }
    if (filter?.aliases) {
        res.push('aliases=' + filter?.aliases.join(','));
    }
    if (res.length > 0) {
        return '&' + res.join('&');
    } else {
        return '';
    }
}

export const { useFetchPortsQuery } = api.injectEndpoints({
    endpoints: build => ({
        fetchPorts: build.query<Ports, APIQueryParams>({
            query(params) {
                const offset = `${params.page && params.size ? `offset=${(Number(params.page) - 1) * (params.size ? params.size : 25)}` : ''}`;
                const limit = `&${params.size ? `limit=${params.size}` : ''}`;
                return `/ports/?${offset}${limit}${params.filter}`;
            }
        }),
    }),
});
