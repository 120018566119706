import { BrowserRouter as Router, Switch as RouterSwitch, Route } from 'react-router-dom';
import {
    createTheme,
    CssBaseline,
    ThemeProvider
} from '@mui/material';
import { Provider } from 'react-redux';
import { LocalizationProvider } from '@mui/lab';
import AdapterLuxon from '@mui/lab/AdapterLuxon';
import Box from '@mui/material/Box';
import { getDesignTokens } from './services/themeConfig';
import MainAppBar from './views/MainAppBar/MainAppBar';
import ServiceLinesList from './views/ServiceLines/ServicelinesList';
import { store } from './store/store';
import GenerateLine from './views/GenerateLine';
import WholeServiceLineWizard from './views/ServiceLineWizard/ServiceLineWizard';
import AlertDialog from './components/AlertDialog/AlertDialog';
import AlertSnackbar from './components/AlertSnackbar/AlertSnackbar';

const lightTheme = createTheme(getDesignTokens('light'));
const darkTheme = createTheme(getDesignTokens('dark'));

export const paths = {
    service_line_wizard: '/service_line_wizard',
    deploy_line: '/deploy-line',
    service_lines_list: '/'
};

/**
 * Default App that wraps everything
 */
export default function App(): JSX.Element {
    // just for illustration how the mode can be switched
    const isDarkMode = false;

    return (
        <Provider store={store}>
            <ThemeProvider theme={isDarkMode ? darkTheme : lightTheme}>
                <LocalizationProvider dateAdapter={AdapterLuxon}>
                    <CssBaseline /> {/* Like normalize.css */}
                    <Router>
                        <MainAppBar />
                        <Box sx={{ padding: 2 }}>
                            <RouterSwitch>
                                <Route path={`${paths.service_line_wizard}/:id?`}>
                                    <WholeServiceLineWizard />
                                </Route>
                                <Route path={`${paths.deploy_line}/:id?`}>
                                    <GenerateLine />
                                </Route>
                                <Route path={paths.service_lines_list}>
                                    <ServiceLinesList />
                                </Route>
                            </RouterSwitch>
                        </Box>
                        <AlertDialog />
                        <AlertSnackbar />
                    </Router>
                </LocalizationProvider>
            </ThemeProvider>
        </Provider>
    );
}
