import React, { FC } from 'react';
// better to use react-router-breadcrumbs. It's easier to implement, no need to pass different properties
// https://github.com/icd2k3/use-react-router-breadcrumbs#readme
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { NavLink } from 'react-router-dom';
import {styled} from '@mui/system';
const SNavLink = styled(NavLink)(
    ({ theme }) => `
    color: ${theme.palette.secondary.main};
`);
const Breadcrumbs: FC = () => {
    const breadcrumbs = useBreadcrumbs();
  
    return (
        <React.Fragment>
            {breadcrumbs.map(({ match, breadcrumb }, index) => 
                <span key={match.url}>
                    <SNavLink to={match.url}>
                        {breadcrumb}/ 
                    </SNavLink>
                </span>
            )}
        </React.Fragment>
    );
};

export default Breadcrumbs;
