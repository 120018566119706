import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { Trans, useTranslation } from 'react-i18next';
import { styled } from '@mui/system';
import Breadcrumbs from '../../components/BreadCrumbs/BreadCrumbs';
import UserAvatar from './UserAvatar';
import ClickableAvatar from '../../components/ClickableAvatar/ClickableAvatar';
import { UserData } from '../../types/types';
import { useFetchUserDataQuery, initialUserDataState } from '../../store/slices/userDataSlice';
import { useAppSelector, useAppDispatch } from '../../store/hooks';
import { openAlertDialog, closeAlertDialog } from '../../components/AlertDialog/AlertDialogSlice';
import { useRetryFetchQuery } from '../../utils/hooks';

const StyledAppBar = styled(AppBar)(
    ({ theme }) => `
    background-color: ${theme.palette.primary.dark};
`);

export default function MainAppBar(): JSX.Element {
    const { data = { results: [] } } = useRetryFetchQuery(
        useFetchUserDataQuery,
    );
    const userData: UserData = data && data.results[0] ? data.results[0] : initialUserDataState;
    const userDataApiStatus = useAppSelector((state) => state.userDataApiStatus);
    const { t } = useTranslation('common');
    const dispatch = useAppDispatch();

    if(userDataApiStatus.statusCode === 403) {
        dispatch(openAlertDialog({
            open: true,
            content: 'You are not loggedIn.',
            buttons: [
                {   title: 'login', 
                    variant: 'contained', 
                    onClick: () => {
                        dispatch(closeAlertDialog());
                        window.location.href = '/login/';
                    }
                }
            ]
        }));
    }

    return (
        <Box sx={{ flexGrow: 1 }}>
            <StyledAppBar position="static" color='primary' sx={{m: 0, p: 0}}>
                <Toolbar>
                    <Box sx={{ flexGrow: 1 }}>
                        <Breadcrumbs />
                    </Box>
                    {
                        userData.id === initialUserDataState.id ?
                            <Button onClick={() => window.location.href = '/login/'}
                                color="secondary"
                            >
                                <Trans t={t}>login</Trans>
                            </Button>
                            :
                            <Stack direction='row' spacing={4}>
                                <ClickableAvatar
                                    onClick={() => window.location.href = process.env.REACT_APP_MDADOMAIN + '/organization/'} // redirect to organization panel in main application
                                    name={userData.organisation.name}
                                    imageUrl={userData.organisation.avatar_url}
                                />
                                <UserAvatar userData={userData} />
                            </Stack>
                    }
                </Toolbar>
            </StyledAppBar>
        </Box>
    );
}
