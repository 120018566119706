import React, { FC } from 'react';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import uniqueId from 'lodash/uniqueId';
import VesselSelector from '../../components/VesselSelector/VesselSelector';
import { VesselPosition } from './serviceLineWizardSlice';
import { Vessel } from '../../store/slices/vesselsSlice';
import { Falsy } from '../../types/types';

type Props = {
  vesselPositions: Array<VesselPosition> | null,
  onVesselChange: (newValue: Vessel | Falsy, index: number) => void
};

const VesselSelectorsList: FC<Props> = ({ vesselPositions, onVesselChange }: Props) => {
    return (
        <div>
            <Stack spacing={4}>
                {
                    vesselPositions?.map((vPosition, index) =>
                        <Grid container alignItems='center' spacing={1} sx={{ml: 0}} key={uniqueId('vessel-grid-')}>
                            <Grid item xs={1}>
                                <span style={{float: 'right'}}>{vPosition.position} </span>
                            </Grid>
                            <Grid item xs={11}>
                                <VesselSelector
                                    key={uniqueId('vessel-selector-')}
                                    vessel={vPosition.vessel?.id ? vPosition.vessel : null}
                                    onChange={(newValue) => onVesselChange(newValue, index)}
                                />
                            </Grid>
                        </Grid>
                    )
                }
            </Stack>
        </div>
    );
};

export default VesselSelectorsList;
