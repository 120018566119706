import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';
import { FetchArgs } from '@reduxjs/toolkit/dist/query/fetchBaseQuery';
import { openAlertDialog, closeAlertDialog } from '../../components/AlertDialog/AlertDialogSlice';
import { openAlertSnackbar } from '../../components/AlertSnackbar/AlertSnackbarSlice';

const alertInCaseOfError = (dispatch: any, status: number | undefined): any => {
    if(status) {
        switch(true) {
            case status > 499:
                dispatch(openAlertSnackbar({
                    open: true,
                    message: 'There was an error on the server. Please try again later or contact the administrator.',
                    actionButtonTitle: 'OK',
                }));
                break;

            case status === 401:
                dispatch(openAlertSnackbar({
                    open: true,
                    message: 'You are not authorized to this action',
                }));
                break;
            
            case status === 403:
                dispatch(openAlertDialog({
                    open: true,
                    content: 'Your session has been expired, please login again',
                    buttons: [
                        {   title: 'login', 
                            variant: 'contained', 
                            onClick: () => {
                                dispatch(closeAlertDialog());
                                window.location.href = '/login/';
                            }
                        }
                    ]
                }));
                break;

            case status === 404: 
                dispatch(openAlertSnackbar({
                    open: true,
                    message: 'The request was not found. Please contact the administrator',
                }));
                break;

            default:
                dispatch(openAlertSnackbar({
                    open: true,
                    message: 'Something went wrong, please try again later.',
                    actionButtonTitle: 'OK',
                }));
                break;
        }
    }    
};

const staggeredBaseQueryWithBailOut = retry(
    async (args: string | FetchArgs, api, extraOptions) => {
        const result = await fetchBaseQuery({ baseUrl: '/api' })(
            args,
            api,
            extraOptions
        );
        const { dispatch } = api;
        if(result?.error) {
            alertInCaseOfError(dispatch, result?.meta?.response?.status);
        }
        return result;
    },
    {
        maxRetries: 3,
    }
);

export const api = createApi({
    reducerPath: 'api',
    baseQuery: staggeredBaseQueryWithBailOut,
    endpoints: () => ({})
});
