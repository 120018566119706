import React, { FC } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Button from '@mui/material/Button';
import { useAppSelector, useAppDispatch } from '../../store/hooks';
import { closeAlertSnackbar } from './AlertSnackbarSlice';

const AlertSnackbar: FC = () => {
    const alertSnackbar = useAppSelector((state) => state.alertSnackbar);
    const dispatch = useAppDispatch();
    const { open, message, actionButtonTitle, actionButtonOnClick, 
        anchorOrigin, autoHideDuration } = alertSnackbar;

    return (
        <Snackbar
            anchorOrigin={anchorOrigin}
            autoHideDuration={autoHideDuration}
            open={open}
            action={actionButtonTitle ? 
                <Button color="secondary" size="small"
                    onClick={ actionButtonOnClick ? actionButtonOnClick : () => dispatch(closeAlertSnackbar())}
                >
                    {actionButtonTitle}
                </Button>
                : undefined
            }
            message={message}
            onClose={() => dispatch(closeAlertSnackbar())}
        />
    );
};

export default AlertSnackbar;
