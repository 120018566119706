import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SnackbarProps, SnackbarOrigin } from '@mui/material/Snackbar';
import { SNACKBAR_AUTO_HIDE_DURATION } from '../../utils/constants';

type ActionButtonType = {
    actionButtonTitle?: string | null,
    actionButtonOnClick?: () => void | any
};

type AlertSnackbar = SnackbarProps & (ActionButtonType);

const anchorOrigin: SnackbarOrigin  = { vertical: 'top', horizontal: 'center' };

const initialState = {
    open: false, 
    message: '',
    actionButtonTitle: null,
    actionButtonOnClick: null,
    anchorOrigin: anchorOrigin,
    autoHideDuration: SNACKBAR_AUTO_HIDE_DURATION
};

export const alertSnackbarSlice = createSlice({
    name: 'alertSnackbar',
    initialState: initialState,
    reducers: {
        openAlertSnackbar: (state, action: PayloadAction<AlertSnackbar>) => {
            Object.assign(state, action.payload);
        },
        closeAlertSnackbar: (state) => {
            Object.assign(state, initialState);
        }
    }
});

export const { openAlertSnackbar, closeAlertSnackbar } = alertSnackbarSlice.actions;

export default alertSnackbarSlice.reducer;
