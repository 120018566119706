import { FC, useState, useEffect } from 'react';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import DatePicker from '@mui/lab/DatePicker';
import { Container, Paper, TextField, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import { DateTime } from 'luxon';
import { useHistory } from 'react-router';
import orderBy from 'lodash/orderBy';
import { useTranslation } from 'react-i18next';
import uniqueId from 'lodash/uniqueId';
import Map from './ServiceLineWizard/Summary';
import ModalCircularProgress from '../components/ModalCircularProgress/ModalCircularProgress';
import {
    updateServiceLine, resetServiceLine,
    ServiceLine, VoyagePartTemplate, VesselPosition
} from './ServiceLineWizard/serviceLineWizardSlice';
import { useAppSelector, useAppDispatch } from '../store/hooks';
import { DATE_FORMAT } from '../utils/constants';
import { secureFetchWithStatus, doesServiceLineHaveEmptyFields, emptyArrayForVesselPositions } from '../utils/utils';
import { openAlertDialog, closeAlertDialog } from '../components/AlertDialog/AlertDialogSlice';
import { paths } from '../App';
import { AppDispatch } from '../store/store';
const voyagePartTemplates = (VPTemplatesArray: Array<any>): Array<VoyagePartTemplate> | Array<any> => {
    const VPTempArray: Array<VoyagePartTemplate> | Array<any> = [];
    orderBy(VPTemplatesArray, template => template.part.ets, ['asc']).forEach(VPT => {
        VPTempArray.push({
            id: VPT.part.id,
            ets: VPT.part.ets,
            ete: VPT.part.ete,
            berth: VPT.part.start_location,
            port: VPT.part.start_location.port,
        });
    });
    return VPTempArray;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const fetchServiceLine = async (serviceLineId: string, dispatch: AppDispatch, history: any): Promise<any> => {
    const ret = await secureFetchWithStatus(`/api/service_lines/${serviceLineId}/`);
    if (ret.http_status === 200) {
        const newServiceLine: ServiceLine = {
            id: ret.result.id,
            name: ret.result.name,
            duration: ret.result.rd_duration,
            frequency: ret.result.frequency,
            refStartDate: ret.result.start_date,
            voyagePartGroupTemplate: {
                voyagePartsTemplates: voyagePartTemplates(ret.result.template.voyage_parts)
            },
            voyagePartGroupTemplateId: ret.result.template.id,
            vesselsPositionList: ret.result.deployed_vessels.length > 0 ?
                ret.result.deployed_vessels.sort(
                    (a: VesselPosition, b: VesselPosition) => a.position - b.position
                )
                : emptyArrayForVesselPositions(ret.result.rd_duration, ret.result.frequency.frequency_hours),
            voyageNumberPattern: ret.result.voyage_number_pattern,
            public: ret.result.public
        };
        dispatch(updateServiceLine(newServiceLine));
    } else {
        history.push(paths.service_line_wizard);
    }
};

const GenerateLine: FC = () => {
    const history = useHistory();
    const serviceLineWizard: ServiceLine = useAppSelector((state) => state.serviceLineWizard);
    const [isLoading, setIsLoading] = useState(false);

    const dispatch = useAppDispatch();
    const { t } = useTranslation('common');
    const { id } = useParams<{ id: string }>();
    const generateVisits = async () => {
        const URL = '/api/deploy_lines/';
        setIsLoading(true);
        const ret = await secureFetchWithStatus(URL, {
            service_line_id: serviceLineWizard.id,
            timeframe_start: serviceLineWizard.startTimeframe,
            timeframe_end: serviceLineWizard.endTimeframe,
        }, 'POST');
        if (ret.http_status === 200) {
            setIsLoading(false);
            dispatch(openAlertDialog({
                open: true,
                content: t('visits_generated'),
                buttons: [
                    {
                        title: t('home_page'),
                        variant: 'contained',
                        onClick: () => {
                            dispatch(closeAlertDialog());
                            history.push(paths.service_lines_list);
                        }
                    }
                ]
            }));
        } else {
            setIsLoading(false);
        }
    };
    const haveEmptyFields = Boolean(!serviceLineWizard.startTimeframe || !serviceLineWizard.endTimeframe);
    const serviceLineHasEmptyFields = doesServiceLineHaveEmptyFields(serviceLineWizard);


    useEffect(() => {
        if (id) {
            fetchServiceLine(id, dispatch, history);
        } else {
            history.push(paths.service_lines_list);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    return (
        <Container sx={{ mt: 4 }}
            maxWidth='lg'
        >
            <Stack spacing={4}>
                <Typography variant="h4">Deploy Lines </Typography>
                <Grid container>
                    <Grid item xs={6} sx={{ p: 2 }}>
                        <Stack spacing={4}>
                            <Paper sx={{ p: 4 }}>
                                <Grid container spacing={1}>
                                    <Grid item xs={6}>
                                        <Typography variant="h6">{t('serviceLineName')}: </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="h6">{serviceLineWizard.name ?? ''}</Typography>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Typography variant="h6">{t('round_trip_duration')}:</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="h6"> {serviceLineWizard.duration ?? ''} days</Typography>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Typography variant="h6">{t('frequency')}:</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="h6"> {serviceLineWizard.frequency?.name ?? ''}</Typography>
                                    </Grid>
                                </Grid>
                            </Paper>
                            <Paper sx={{ p: 4 }}>
                                <Typography variant={'h6'} component={'h2'}
                                    sx={{ mb: 4, mr: 4, width: 'fit-content', float: 'left' }}
                                >
                                    {t('operating_vessels')}:
                                </Typography>
                                {
                                    serviceLineWizard.vesselsPositionList?.map((vPosition, index) =>
                                        vPosition.vessel?.name ?
                                            <Chip label={vPosition.vessel?.name} size="small"
                                                key={uniqueId('vessel-grid-')}
                                            />
                                            : null
                                    )
                                }
                            </Paper>
                        </Stack>
                    </Grid>
                    <Grid item xs={6}>
                        <Map />
                    </Grid>
                </Grid>

                <Paper sx={{ p: 4 }} >
                    <Typography variant={'h5'} component={'h2'} sx={{ mb: 4 }}>{t('time_frame')}</Typography>
                    <Stack direction="row" spacing={2}>
                        <DatePicker
                            label={t('from')}
                            inputFormat={DATE_FORMAT}
                            renderInput={(params: any) =>
                                <TextField {...params} />
                            }
                            value={serviceLineWizard.startTimeframe ?
                                DateTime.fromSeconds(serviceLineWizard.startTimeframe).toJSDate()
                                : null
                            }
                            disabled={serviceLineHasEmptyFields}
                            onChange={(date: DateTime | null) => { //We have to set the type to DateTime, if not it will be default Date from JS
                                const refStartDateTime = serviceLineWizard.refStartDate ?
                                    DateTime.fromSeconds(serviceLineWizard.refStartDate) : null;
                                const refStartHour = refStartDateTime?.get('hour') ?? undefined;
                                const refStartMinute = refStartDateTime?.get('minute') ?? undefined;
                                if (date) {
                                    const newDateWithProperTime = date.set({ hour: refStartHour, minute: refStartMinute });
                                    dispatch(updateServiceLine({ startTimeframe: newDateWithProperTime.toSeconds() }));
                                }
                            }}
                        />
                        <DatePicker
                            label={t('to')}
                            inputFormat={DATE_FORMAT}
                            renderInput={(params: any) =>
                                <TextField {...params} />
                            }
                            value={serviceLineWizard.endTimeframe ?
                                DateTime.fromSeconds(serviceLineWizard.endTimeframe)
                                : null
                            }
                            minDate={serviceLineWizard.startTimeframe ?
                                DateTime.fromSeconds(serviceLineWizard.startTimeframe).plus({ days: serviceLineWizard.duration ?? undefined })
                                : undefined
                            } // It should be to refStartDate + duration (means one round trip)
                            disabled={serviceLineHasEmptyFields}
                            onChange={(date: DateTime | null) => {
                                if (date) {
                                    const newDateWithProperTime = date.set({ hour: 23, minute: 59 }); // Set it to the end of the selected date
                                    dispatch(updateServiceLine({ endTimeframe: newDateWithProperTime.toSeconds() }));
                                }
                            }}
                        />
                    </Stack>
                </Paper>
            </Stack>
            <Stack spacing={2} sx={{ mt: 4 }} direction='row' justifyContent='space-between' alignItems='center'>
                <Button color='error'
                    onClick={() => {
                        dispatch(resetServiceLine());
                        history.push(paths.service_lines_list);
                    }}
                >{t('cancel')}</Button>
                {
                    serviceLineHasEmptyFields ?
                        <Box>
                            <Typography>{t('service_line_empty_field_warning')}:</Typography>
                            <Button
                                onClick={() => history.push(paths.service_line_wizard)}
                            >
                                {t('to_edit_line')}
                            </Button>
                        </Box>
                        :
                        <Button
                            variant='contained'
                            onClick={generateVisits}
                            disabled={haveEmptyFields}
                        >
                            {t('generate_visits')}
                        </Button>
                }
            </Stack>
            <ModalCircularProgress open={isLoading} />
        </Container>
    );
};

export default GenerateLine;
