import { FC, useState, useEffect } from 'react';
import Box from '@mui/system/Box';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';

type Props = {
    hasSeconds?: boolean,
    duration?: number, // duration in seconds
    onChange?: (duration: number) => void,
    rootStyle?: any,
};

const DurationPicker: FC<Props> = ({ hasSeconds = false, duration, onChange, rootStyle }: Props) => {

    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
    const [days, setDays] = useState<number>(0);
    const [hours, setHours] = useState<number>(0);
    const [minutes, setMinutes] = useState<number>(0);
    const [seconds, setSeconds] = useState<number>(0);
    const [newDuration, setNewDuration] = useState<number | null>(null);
    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const onDaysChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const days = Number.parseFloat(event.target.value);
        if (Number.isNaN(days)) {
            setDays(0);
        } else {
            setDays(days);
        }
    };
    const onHoursChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const hours = Number.parseFloat(event.target.value);
        if (Number.isNaN(hours)) {
            setHours(0);
        } else {
            setHours(hours);
        }
    };
    const onMinutesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const minutes = Number.parseFloat(event.target.value);
        if (Number.isNaN(minutes)) {
            setMinutes(0);
        } else {
            setMinutes(minutes);
        }
    };
    const onSecondsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const seconds = Number.parseFloat(event.target.value);
        if (Number.isNaN(seconds)) {
            setSeconds(0);
        } else {
            setSeconds(seconds);
        }
    };

    const recalculateDuration = (duration: number): void => {
        const days = Math.floor(duration / 24 / 3600);
        const hours = Math.floor((duration - days * 24 * 3600) / 3600);
        const minutes = Math.floor((duration - days * 24 * 3600 - hours * 3600) / 60);
        const seconds = duration - days * 24 * 3600 - hours * 3600 - minutes * 60;
        setDays(days);
        setHours(hours);
        setMinutes(minutes);
        setSeconds(seconds);
    };

    const onBlur = () => {
        const newDuration = days * 24 * 3600 + hours * 3600 + minutes * 60 + seconds;
        setNewDuration(newDuration);
        if (onChange) {
            onChange(newDuration);
        }
    };

    useEffect(() => {
        if (typeof duration === 'number') {
            recalculateDuration(duration);
        }
    }, [duration]);

    useEffect(() => {
        if (typeof newDuration === 'number') {
            recalculateDuration(newDuration);
        }
    }, [newDuration]);


    return (
        <Box sx={rootStyle}>
            <Box
                sx={{
                    borderRadius: 1,
                    padding: '0 3px',
                    border: '1px solid #BDBDBD',
                    cursor: 'pointer',
                    display: 'flex',
                    '&:hover': {
                        border: '1px solid black',
                    }
                }}
                aria-describedby={id}
                onClick={handleClick}
            >
                <Box sx={{ lineHeight: 1, textAlign: 'center', padding: '5px 3px', }}>
                    <Box sx={{ boxSizing: 'border-box' }}>{days}</Box>
                    <Box sx={{ fontSize: '70%' }}>days</Box>
                </Box>
                <Box sx={{ lineHeight: 1, textAlign: 'center', padding: '5px 3px', }}>
                    <Box sx={{ boxSizing: 'border-box' }}>{hours}</Box>
                    <Box sx={{ fontSize: '70%' }}>hours</Box>
                </Box>
                <Box sx={{ lineHeight: 1, textAlign: 'center', padding: '5px 3px', }}>
                    <Box sx={{ boxSizing: 'border-box' }}>{minutes}</Box>
                    <Box sx={{ fontSize: '70%' }}>minutes</Box>
                </Box>
                {
                    hasSeconds ?
                        <Box sx={{ lineHeight: 1, textAlign: 'center', padding: '5px 3px', }}>
                            <Box sx={{ boxSizing: 'border-box' }}>{seconds}</Box>
                            <Box sx={{ fontSize: '70%' }}>seconds</Box>
                        </Box>
                        :
                        null
                }
            </Box>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Stack spacing={2}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <TextField
                            label="days"
                            type="number"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            sx={{
                                width: 100,
                            }}
                            InputProps={{ inputProps: { min: 0 } }}
                            value={days}
                            onChange={onDaysChange}
                            onBlur={onBlur}
                        />
                        <Typography sx={{ ml: 1 }}>days</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <TextField
                            label="hours"
                            type="number"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            sx={{
                                width: 100,
                            }}
                            InputProps={{ inputProps: { min: 0 } }}
                            value={hours}
                            onChange={onHoursChange}
                            onBlur={onBlur}
                        />
                        <Typography sx={{ ml: 1 }}>hours</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <TextField
                            label="minutes"
                            type="number"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            sx={{
                                width: 100,
                            }}
                            InputProps={{ inputProps: { min: 0 } }}
                            value={minutes}
                            onChange={onMinutesChange}
                            onBlur={onBlur}
                        />
                        <Typography sx={{ ml: 1 }}>minutes</Typography>
                    </Box>
                    {
                        hasSeconds ?
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <TextField
                                    label="seconds"
                                    type="number"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    sx={{
                                        width: 100,
                                    }}
                                    InputProps={{ inputProps: { min: 0 } }}
                                    value={seconds}
                                    onChange={onSecondsChange}
                                    onBlur={onBlur}
                                />
                                <Typography sx={{ ml: 1 }}>seconds</Typography>
                            </Box> :
                            null
                    }
                </Stack>
            </Popover>
        </Box>
    );
};

export default DurationPicker;
