import { FC, useRef } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { useResizeDetector } from 'react-resize-detector';
// import SleipnerMap from '../../components/Leaflet/Map';
import SleipnerMap from '../../components/Mapbox/Map';
import { useAppSelector } from '../../store/hooks';
import { DEBOUNCE_DELAY } from '../../utils/constants';


const Summary: FC = () => {

    const targetRef = useRef<any>();
    useResizeDetector({ targetRef, refreshMode: 'debounce', refreshRate: DEBOUNCE_DELAY });
    const vpGroupTemplate = useAppSelector((state) => state.serviceLineWizard.voyagePartGroupTemplate);
    const markers = new Map();
    vpGroupTemplate?.voyagePartsTemplates?.forEach((template, index) => {
        markers.set(index, { name: template.port?.name, position: { lat: template.port?.lat, lon: template.port?.lon } });
    });

    return (
        <Box sx={{ p: 2, display: 'flex', height: '100%' }}>
            <Paper ref={targetRef} sx={{ flexGrow: 1, ml: 2 }}>
                <SleipnerMap style={{ height: '100%', width: '100%' }} markers={Array.from(markers.values())} />
            </Paper>
        </Box>
    );
};

export default Summary;
