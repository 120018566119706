import { ListResponse, APIQueryParams } from '../../types/types';
import { api } from './apiSlice';

export interface Vessel {
    id?: string,
    name?: string,
    country?: string,
    imo?: number,
    mmsi?: number,
}

export interface Vessels {
    count: number,
    next: string,
    previous: string,
    results: Array<Vessel>
}

export interface VesselsFilter {
    name?: string,
    country?: string,
    mmsi?: number,
    imo?: number,
    aliases?: Array<string>


}

export const filter2String = (filter: VesselsFilter) : string => {
    const r = [];
    if (filter && filter.name) {
        r.push('name=' + filter.name);
    }
    if (filter && filter.country) {
        r.push('country=' + filter.country);
    }
    if (filter && filter.imo) {
        r.push('imo=' + String(filter.imo));
    }
    if (filter && filter.country) {
        r.push('mmsi=' + String(filter.mmsi));
    }
    if (r.length > 0) {
        return '&' + r.join('&');
    } else {
        return '';
    }
};

export const { useFetchVesselsQuery } = api.injectEndpoints(
    {
        endpoints: build => ({
            fetchVessels: build.query<ListResponse<Vessel>, APIQueryParams>({
                query(params) {
                    return `/vessels/?offset=${(Number(params.page) - 1) * (params.size ? params.size : 25)}&limit=${params.size ? params.size : 25}`
                        + params.filter;
                }
            }),
        }),
    }
);
