import { FC } from 'react';
import Dialog from '@mui/material/Dialog';
import CircularProgress, { CircularProgressProps, } from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const ModalCircularProgress: FC<CircularProgressProps & { open: boolean, value? : number }> =
    ({ open, value, ...restProps }) => {
        return (
            <Dialog open={open}>
                <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                    <CircularProgress variant={value ? 'determinate' : 'indeterminate'} value={value} {...restProps} />
                    <Box
                        sx={{
                            top: 0,
                            left: 0,
                            bottom: 0,
                            right: 0,
                            position: 'absolute',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        {
                            value ? 
                                <Typography
                                    variant="caption"
                                    component="div"
                                    color="text.secondary"
                                >
                                    {`${Math.round(value)}%`}
                                </Typography>
                                : null
                        }

                    </Box>
                </Box>
            </Dialog>
        );
    };

export default ModalCircularProgress;
