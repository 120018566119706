import { FC } from 'react';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { DateTime } from 'luxon';
import uniqueId from 'lodash/uniqueId';
import { useTranslation } from 'react-i18next';
import RoundTripDescriptionItem from './RoundTripDescriptionItem';
import { useAppSelector, useAppDispatch } from '../../store/hooks';
import { addVoyagePartTemplate } from './serviceLineWizardSlice';

const RoundTripDescription: FC = () => {
    const serviceLineWizard = useAppSelector((state) => state.serviceLineWizard);
    const vpGroupTemplate = serviceLineWizard.voyagePartGroupTemplate;
    const dispatch = useAppDispatch();
    const minDate = serviceLineWizard.refStartDate ? DateTime.fromSeconds(serviceLineWizard.refStartDate) : null;
    const maxDate = minDate && serviceLineWizard.duration ? minDate?.plus({ days: serviceLineWizard.duration }) : null;
    const { t } = useTranslation('common');

    return (
        <Paper sx={{ maxHeight: '50vh', overflowY: 'auto', m: 2, p: 4 }} >
            <Typography variant={'h6'} component={'h2'} sx={{ mb: 4 }}>{t('locations')}</Typography>
            <Stack spacing={2}>
                {vpGroupTemplate?.voyagePartsTemplates.map((vpTemplate, i) =>
                    <RoundTripDescriptionItem
                        index={i}
                        key={uniqueId()} // use uniqueid as a key, to avoid bugs in rendering items
                        port={vpTemplate.port}
                        ets={vpTemplate.ets}
                        ete={vpTemplate.ete}
                        berth={vpTemplate.berth}
                        minDate={minDate}
                        maxDate={maxDate}
                        id={vpTemplate.id}
                        timestamps={vpGroupTemplate.voyagePartsTemplates.map(vpTemplate => [vpTemplate.ets, vpTemplate.ete]).flat()}
                    />)}
            </Stack>
            <Button variant='contained'
                onClick={() => dispatch(addVoyagePartTemplate())}
                sx={{ mt: 4 }}
            >
                Add
            </Button>
        </Paper>
    );
};

export default RoundTripDescription;
