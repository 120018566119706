import { ServiceLine } from '../views/ServiceLineWizard/serviceLineWizardSlice';
import { openAlertSnackbar } from '../components/AlertSnackbar/AlertSnackbarSlice';
import { SNACKBAR_AUTO_HIDE_DURATION } from './constants';
import { store } from '../store/store';

export const filter2String = (filter?: Record<string, any>): string => {
    if (filter) {
        const keys = Object.keys(filter);
        const filterStr = keys.map(key => {
            if (Array.isArray(filter[key])) {
                return `${key}=${filter[key].join(',')}`;
            }
            return `${key}=${filter[key]}`;
        }).join('&');
        return filterStr;
    }
    return '';
};

export const getXCSRFToken = (): string | null => {
    if (!document.cookie) {
        return null;
    }

    const xsrfCookies = document.cookie.split(';')
        .map(c => c.trim())
        .filter(c => c.startsWith('csrftoken='));

    if (xsrfCookies.length === 0) {

        return null;
    }
    return decodeURIComponent(xsrfCookies[0].split('=')[1]);
};

export const secureFetchWithStatus = async function (URL: string, params = {}, method = 'GET', informUser = true):
    Promise<{ http_status: number, result: any }> {
    let response;
    const init: any = {
        method,
        headers: {
            'X-CSRFToken': getXCSRFToken()
        },
        credentials: 'include', // todo: is this secure? same-origin should be default (and more secure). test that!
    };
    if (method.toUpperCase() !== 'GET') {
        try {
            const encodedParams = JSON.stringify(params);
            init['headers']['Content-Type'] = 'application/json';
            init['body'] = encodedParams; // if method is get, a request body is forbidden (browser will throw an error)
        } catch (e) {
            console.error('parameters could not be encoded to JSON', e);
        }
    }
    try {
        response = await fetch(URL, init);
    } catch (e) {
        console.error('Network error! Could not execute request', e);
        if (informUser) {
            store.dispatch(openAlertSnackbar({
                open: true,
                message: 'There is a problem with the network connection',
                actionButtonTitle: 'Reload',
                actionButtonOnClick: () => window.location.reload(),
                autoHideDuration: SNACKBAR_AUTO_HIDE_DURATION
            }));
        }
        return {
            http_status: 901,
            result: null,
        };
    }
    // We assume that the server ALWAYS responds with JSON
    let result;
    let status_code = response.status;
    result = await response.text(); // We always parse as text, because we cant read the stream again, if .json() fails
    try {
        result = JSON.parse(result);
    } catch (e) {
        if (status_code === 200) {
            status_code = 900;
        }
    }

    if (status_code === 200 || status_code === 201 || status_code === 204 || status_code === 400) {
        return {
            http_status: status_code,
            result: result,
        };
    } else {
        switch (status_code) {
            case 401: // unauthorized
                // String is coming from 'MdaUserAuthentication'
                if (result.detail && result.detail.toUpperCase() === 'USER IS ANONYMOUS') {
                    store.dispatch(openAlertSnackbar({
                        open: true,
                        message: 'Your session expired, please login again',

                        autoHideDuration: SNACKBAR_AUTO_HIDE_DURATION
                    }));
                } else if (informUser) {
                    store.dispatch(openAlertSnackbar({
                        open: true,
                        message: 'You are not authorized to this action',
                        autoHideDuration: SNACKBAR_AUTO_HIDE_DURATION
                    }));
                }
                break;
            case 900:
                console.error('Internal error occurred. JSON expected, that could not be parsed. THIS SHOULD NEVER HAPPEN');
                store.dispatch(openAlertSnackbar({
                    open: true,
                    message: 'There was an error on the server. Please contact an administrator.',
                    actionButtonTitle: 'OK',
                }));
                // throw 'Internal error occurred';
                break;
            case 403:
                console.error('Fetch failed: Unauthorized activity:', status_code, 'RESPONSE:', result);
                if (informUser) {
                    store.dispatch(openAlertSnackbar({
                        open: true,
                        message: 'You are not authorized to this action',
                        autoHideDuration: SNACKBAR_AUTO_HIDE_DURATION
                    }));
                }
                break;
            default:
                console.error('Fetch failed: status code is not HTTP_200_OK. ACTUAL STATUS CODE:', status_code, 'RESPONSE:', result);
                if (informUser) {
                    store.dispatch(openAlertSnackbar({
                        open: true,
                        message: 'A request to the server failed, pleas try again later',
                        actionButtonTitle: 'OK',
                    }));
                }
        }
        return {
            http_status: status_code,
            result: null,
        };
    }
};

export const emptyArrayForVesselPositions = (roundDuration: number, FrequencyHours: number): Array<any> => {
    let vesselPositionLength = Math.floor(roundDuration / (FrequencyHours / 24));
    if (vesselPositionLength === 0) {
        vesselPositionLength = 1;
    }
    return Array(vesselPositionLength).fill(null).map((e, index) => {
        return { position: index, vessel: {} };
    });

};

export const doesServiceLineHaveEmptyFields = (serviceLine : ServiceLine): boolean => {
    const { name, duration, frequency, refStartDate, vesselsPositionList, isVNPatternValid } = serviceLine;
    let haveEmptyFields = Boolean(!name || !duration || !frequency || !refStartDate || !isVNPatternValid);
    if(!haveEmptyFields) {
        serviceLine.voyagePartGroupTemplate?.voyagePartsTemplates?.forEach(VPTemplate => {
            if (!VPTemplate.port || !VPTemplate.berth || !VPTemplate.ete || !VPTemplate.ets) {
                haveEmptyFields = true;
            }
        });
        if (!haveEmptyFields) {
            vesselsPositionList?.forEach(vPosition => {
                if (!vPosition.vessel?.id) {
                    haveEmptyFields = true;
                }
            });
        }
    }
    return haveEmptyFields;
};
