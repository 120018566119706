import React, { FC } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Trans, useTranslation } from 'react-i18next';
import { UserData } from '../../types/types';
import ClickableAvatar from '../../components/ClickableAvatar/ClickableAvatar';

type UserAvatarProps = {
    userData: UserData
}

const UserAvatar: FC<UserAvatarProps> = (props) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const { t } = useTranslation('common');

    return (
        <div>
            <ClickableAvatar
                onClick={handleClick}// opens a menue with two items
                name={props.userData.username}
                imageUrl={props.userData.avatar_url}
            />
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                onClick={handleClose}
            >
                <MenuItem
                    onClick={() => window.location.href = process.env.REACT_APP_MDADOMAIN + '/profile/'} //Should have a proper path
                >
                    <Trans t={t}>profile</Trans>
                </MenuItem>
                <MenuItem
                    onClick={() => window.location.href = '/logout/' || ''}
                >
                    <Trans t={t}>logout</Trans>
                </MenuItem>
            </Menu>
        </div>
    );
};

export default UserAvatar;
