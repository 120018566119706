import {FC, useState} from 'react';
import Lock from '@mui/icons-material/Lock';
import LockOpen from '@mui/icons-material/LockOpen';
import ToggleButton from '@mui/material/ToggleButton';
import { Falsy } from '../../types/types';

type Props = {
  lineID?: number | Falsy,
  isPublic: boolean,
  onChange?: (isPublic: boolean) => any
}

const PublicButton: FC<Props> = ({ lineID, isPublic, onChange = () => null }: Props) => {

    return (
        <ToggleButton
            value="check"
            selected={isPublic}
            onChange={() => onChange(!isPublic)}
            title={isPublic ? 'Public' : 'Private'}
            size='small'
            sx={{height: 'fit-content', alignSelf: 'center'}}
        >
            {
                !isPublic ? 
                    <Lock fontSize='small'/>
                    : 
                    <LockOpen fontSize='small'/>
            }
        </ToggleButton>
    );
};

export default PublicButton;
