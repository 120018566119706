import React, { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Pagination from '@mui/material/Pagination';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Search from '@mui/icons-material/Search';
import { Trans, useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import debounce from 'lodash/debounce';
import { useAppDispatch } from '../../store/hooks';
import { resetServiceLine } from '../ServiceLineWizard/serviceLineWizardSlice';
import { useFetchServiceLinesQuery, filter2String as convert,
    ServiceLineInfo } from '../../store/slices/serviceLinesSlice';
import { useFetchUserDataQuery } from '../../store/slices/userDataSlice';
import { DEBOUNCE_DELAY } from '../../utils/constants';
import { paths } from '../../App';
import { useRetryFetchQuery } from '../../utils/hooks';
import PublicButton from '../../components/PublicButton/PublicButton';
import { secureFetchWithStatus } from '../../utils/utils';

const ServiceLinesList: FC = () => {
    const [pageNumber, setPageNumber] = useState(1);
    const [filterName, setFilterName] = useState('');
    const pageSize = 10;
    const userData = useFetchUserDataQuery().data;
    const isUserLoggedIn = Boolean(userData && userData.results[0]);
    const { data = { results: [], count: 0 }, refetch } = useRetryFetchQuery(
        useFetchServiceLinesQuery, 
        { 
            page: pageNumber, 
            size: pageSize, 
            filter: convert({name: filterName})
        },
        {
            skip: !isUserLoggedIn,
            refetchOnMountOrArgChange: true
        }
    );
    const { t } = useTranslation('common');
    const theme = useTheme();
    const history = useHistory();
    const dispatch = useAppDispatch();

    const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
        setPageNumber(value);
    };

    const onChangeFilterName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFilterName(event.target.value);
    };

    const onChangePublicAttr = async (serviceLine: ServiceLineInfo ,newPublicState: boolean) => {
        const res = await secureFetchWithStatus(`/api/service_lines/${serviceLine.id}/`,
            {
                name: serviceLine.name,
                rd_duration: serviceLine.rd_duration, // duration in seconds
                frequency: serviceLine.frequency?.id,
                start_date: serviceLine.start_date,
                voyage_number_pattern: serviceLine.voyage_number_pattern,
                public: newPublicState
            },
            'PUT'
        );
        if (res.http_status === 200) {
            refetch();
        }
    };

    const debouncedOnChangeFilter = debounce(onChangeFilterName, DEBOUNCE_DELAY);

    return (
        <Container maxWidth="sm">
            <Typography variant="h4" sx={{ margin: 5 }}>
                {t('serviceLines')}
            </Typography>
            <Paper sx={{ width: 600 }} elevation={3}>
                <TextField
                    label={t('filter_by_name')}
                    sx={{m: 5}}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search />
                            </InputAdornment>
                        ),
                    }}
                    onChange={debouncedOnChangeFilter}
                />
                <List >
                    {data.results.map((sl: ServiceLineInfo) =>
                        <ListItem
                            sx={{ paddingRight: 0, 
                                '&:hover': { backgroundColor: theme.palette.grey[100] } 
                            }}
                            key={sl.id}
                            secondaryAction={
                                <Stack direction="row" spacing={2}>
                                    <Button
                                        variant='outlined' color='primary'
                                        // TODO: redirect to details page
                                        onClick={() => {
                                            history.push(`${paths.service_line_wizard}/${sl.id}`);
                                        }}
                                    >
                                        <Trans t={t}>edit</Trans>
                                    </Button>
                                    <Button
                                        variant='outlined' color='primary'
                                        // push the deploy route to the history 
                                        onClick={() => {
                                            history.push(`${paths.deploy_line}/${sl.id}`);
                                        }}
                                    >
                                        <Trans t={t}>generate_visits</Trans>
                                    </Button>
                                    <PublicButton 
                                        lineID={sl.id}
                                        isPublic={sl.public}
                                        onChange={(newPublicState) => onChangePublicAttr(sl, newPublicState) }
                                    />
                                </Stack>
                            }
                        >
                            <ListItemText>{sl.name}</ListItemText>
                        </ListItem>
                    )}
                </List>
                <Pagination count={Math.ceil(data.count / pageSize)} page={pageNumber} onChange={handleChangePage}
                    sx={{ pb: 2, ml: 10 }}
                />
            </Paper>
            <Button
                sx={{ ml: 5, mt: 4 }}
                variant='contained' color='primary'
                onClick={() => {
                    dispatch(resetServiceLine());
                    history.push(paths.service_line_wizard);
                }}
            >
                <Trans t={t}>newServiceLine</Trans>
            </Button>
        </Container>
    );
};

export default ServiceLinesList;
