import React, { useState } from 'react';
import {
    Paper,
    Stack,
    Typography,
    Collapse,
    Button,
    Chip,
    Divider
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import uniqueId from 'lodash/uniqueId';
import { updateVesselPosition, VesselPosition } from './serviceLineWizardSlice';
import { useAppSelector, useAppDispatch } from '../../store/hooks';
import { Vessel } from '../../store/slices/vesselsSlice';
import VesselSelectorsList from './VesselSelectorsList';
import { openAlertDialog } from '../../components/AlertDialog/AlertDialogSlice';
import { Falsy } from '../../types/types';


export default function VesselsList(): JSX.Element {
    const { t } = useTranslation('common');
    const serviceLineWizard = useAppSelector((state) => state.serviceLineWizard);
    const dispatch = useAppDispatch();
    const [collapsedIn, setCollapse] = useState(false);
    const vesselPositions = serviceLineWizard.vesselsPositionList ?? [];
    let leftSideArray: Array<VesselPosition> | null = null;
    let rightSideArray: Array<VesselPosition> | null = null;
    if(vesselPositions && vesselPositions.length > 5) {
        const halfLength = Math.ceil(vesselPositions.length / 2);
        rightSideArray = [...vesselPositions];
        leftSideArray = [...rightSideArray.splice(0, halfLength)];
    }

    const onVesselChange = (index: number, newValue: Vessel | Falsy) => {
        let alreadyExists: boolean | undefined = false;
        if(newValue) {
            alreadyExists = vesselPositions.some((vesselPosition, vIndex) => vesselPosition.vessel?.id === newValue.id && vIndex !== index);
        }
        if(alreadyExists) {
            dispatch(openAlertDialog({
                open: true,
                content: t('vessel_already_selected_warning'),
                buttons: [{   
                    title: t('ok'), 
                    variant: 'contained', 
                }]
            }));
        } else {
            dispatch(updateVesselPosition({ index, newValue }));
        }
    };

    return (
        <Paper>
            <div style={{display: 'grid'}}>
                <div>
                    <Typography variant={'h6'} component={'h2'} 
                        sx={{ mb: 4, mr: 4, width: 'fit-content', float: 'left' }}
                    >
                        {t('operating_vessels')}
                    </Typography>
                    { 
                        vesselPositions.map((vPosition, index) =>
                            vPosition.vessel?.name && index < 3 ? 
                                <Chip label={vPosition.vessel?.name} size="small"
                                    key={uniqueId('vessel-chips-')}
                                />
                                : null
                        )
                    }
                    { vesselPositions.length > 3 ? ' ... ' : null}
                    ({vesselPositions.length})
                    <Button onClick={() => setCollapse(!collapsedIn)} sx={{float: 'right'}} variant="text">
                        {collapsedIn ? 'Collapse' : 'Show Vessels'}
                    </Button>
                </div>
                <div>
                    <Collapse orientation="vertical" in={collapsedIn}>
                        <Stack spacing={4}>
                            {
                                vesselPositions && vesselPositions.length > 5 ? // If the list is long, we split it into right & left list
                                    <Stack  direction="row" spacing={6}
                                        divider={<Divider orientation="vertical" flexItem />}
                                    >
                                        <VesselSelectorsList vesselPositions={leftSideArray}
                                            onVesselChange={(newValue: Vessel | Falsy, index: number) => 
                                                onVesselChange(index, newValue)}
                                        />
                                        <VesselSelectorsList vesselPositions={rightSideArray}
                                            onVesselChange={(newValue: Vessel | Falsy, index: number) => 
                                                onVesselChange(index +  Math.ceil(vesselPositions.length / 2), newValue)}
                                        />
                                    </Stack>
                                    : 
                                    <VesselSelectorsList vesselPositions={vesselPositions}
                                        onVesselChange={(newValue: Vessel | Falsy, index: number) => 
                                            onVesselChange(index, newValue)}
                                    />
                            }
                        </Stack>
                    </Collapse>
                </div>
            </div>
        </Paper>
    );
}
