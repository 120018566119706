import { FC, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Typography from '@mui/material/Typography';
import debounce from 'lodash/debounce';
import Box from '@mui/system/Box';
import Highlighter from 'react-highlight-words';
import { useFetchPortsQuery, Port, filter2String as convert } from '../../store/slices/portsSlice';
import { DEBOUNCE_DELAY, TEXTFIELD_MAXWIDTH } from '../../utils/constants';
import { Falsy } from '../../types/types';
import { useRetryFetchQuery } from '../../utils/hooks';

type Props = {
    dense?: boolean;
    onChange?: (port: Port | Falsy, id?: number) => void,
    id?: number,
    port?: Port | Falsy,
    rootStyle?: any,
}

const PortSelector: FC<Props> = ({ dense, onChange, id, port, rootStyle }: Props) => {
    const [searchedText, setSearchedText] = useState('');
    const { data, isFetching } = useRetryFetchQuery(
        useFetchPortsQuery,
        { page: 1, size: 30, filter: convert({ name: searchedText }) }
    );
    const onInputChange = (event: React.SyntheticEvent, newInputValue: string) => {
        setSearchedText(newInputValue);
    };
    const debouncedOnInputChange = debounce(onInputChange, DEBOUNCE_DELAY);
    return (
        <Autocomplete
            disablePortal
            value={port}
            onChange={(event: any, newValue: Port | null | undefined) => {
                if (onChange) {
                    onChange(newValue, id);
                }
            }}
            onInputChange={debouncedOnInputChange}
            options={data?.results ?? []}
            renderInput={(params) => <TextField {...params} label='Port' 
                sx={{ width: '100%', maxWidth: TEXTFIELD_MAXWIDTH}}
            />}
            getOptionLabel={(option) => option.name || ''}
            autoHighlight
            loading={isFetching}
            size={'small'}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderOption={(props, option) => (
                <Box component='li' {...props} sx={{ display: 'flex', flexDirection: 'column' }} key={option.id}>
                    <Typography variant='subtitle2' gutterBottom component='div' align='left' sx={{ width: '100%' }}>
                        <Highlighter searchWords={[searchedText]} textToHighlight={option.name || ''} />
                    </Typography>
                    <Typography variant='caption' gutterBottom component='div' align='left' sx={{ width: '100%' }}>
                        LOCODE: <Box component="span" fontWeight='fontWeightMedium'>{option.locode}</Box>&nbsp;
                        Country: <Box component="span" fontWeight='fontWeightMedium'>{option.country}</Box>
                    </Typography>
                </Box>
            )}
            sx={rootStyle}
        />
    );
};

export default PortSelector;
