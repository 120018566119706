import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'react-redux'; 
import { store } from './store/store';
// import i18n (needs to be bundled ;)) 
import './services/i18n';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <App/> 
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);
