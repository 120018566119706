import { ChangeEvent, useState } from 'react';
import {
    Paper,
    TextField,
    FormControl,
    InputLabel,
    MenuItem,
    Stack,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useTranslation } from 'react-i18next';
import { useFetchLineFrequenciesQuery } from '../../store/slices/lineFrequencySlice';
import { updateServiceLine } from './serviceLineWizardSlice';
import { useAppSelector, useAppDispatch } from '../../store/hooks';
import { LineFrequency } from '../../store/slices/lineFrequencySlice';
import { VesselPosition } from './serviceLineWizardSlice';
import { TEXTFIELD_MAXWIDTH } from '../../utils/constants';
import { emptyArrayForVesselPositions } from '../../utils/utils';
import { useRetryFetchQuery } from '../../utils/hooks';

export default function ServiceLineWizard(): JSX.Element {
    const { t } = useTranslation('common');
    const [vnpError, setVnpError] = useState(false);
    const { data = { results: [], count: 0 } } = useRetryFetchQuery(
        useFetchLineFrequenciesQuery, 
        { page: 1 }
    );
    const serviceLineWizard = useAppSelector((state) => state.serviceLineWizard);
    const dispatch = useAppDispatch();

    const changeVesselsPositionsList = (frequency?: LineFrequency | null, duration?: number | null) => {
        let vesselsArray: Array<VesselPosition> | null = [];
        if (frequency && duration) {
            vesselsArray = emptyArrayForVesselPositions(duration, frequency.frequency_hours);
            dispatch(updateServiceLine({ vesselsPositionList: vesselsArray }));
        } else {
            dispatch(updateServiceLine({ vesselsPositionList: null }));
        }
    };

    const onPatternChange = (event: ChangeEvent<HTMLInputElement>) => {
        const voyageNumberPattern = /^(\{(dd|mm|yy|yyyy|scn|uuid)\}|\w)*$/i;
        if (!voyageNumberPattern.test(event.target.value)) {
            setVnpError(true);
            dispatch(updateServiceLine({ voyageNumberPattern: event.target.value, isVNPatternValid: false, }));
        } else {
            setVnpError(false);
            dispatch(updateServiceLine({ voyageNumberPattern: event.target.value, isVNPatternValid: true, }));
        }
    };

    return (
        <Paper>
            <Stack direction="row" spacing={4}>
                <TextField
                    name='name'
                    label={t('serviceLineName')}
                    value={serviceLineWizard.name ?? ''}
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                        dispatch(updateServiceLine({ name: event.target.value }))
                    }
                />
                <TextField
                    name='duration'
                    label={t('round_trip_duration_label')}
                    type='number'
                    value={serviceLineWizard.duration ?? ''}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        // The + convert string to number in typescript
                        dispatch(updateServiceLine({ duration: event.target.value ? +event.target.value : null }));
                        changeVesselsPositionsList(serviceLineWizard.frequency, +event.target.value);

                    }}
                />
                <FormControl sx={{ width: TEXTFIELD_MAXWIDTH }} size='small'>
                    <InputLabel id='frequency-select-label'>
                        {t('frequency')}
                    </InputLabel>
                    <Select
                        labelId='frequency-select-label'
                        id='frequency-select-label'
                        value={serviceLineWizard.frequency ? JSON.stringify(serviceLineWizard.frequency) : ''}
                        label={t('frequency')}
                        onChange={(event: SelectChangeEvent) => {
                            dispatch(updateServiceLine({ frequency: JSON.parse(event.target.value) }));
                            changeVesselsPositionsList(JSON.parse(event.target.value), serviceLineWizard.duration);
                        }}
                    >
                        {data.results.map((freq: LineFrequency) =>
                            <MenuItem value={JSON.stringify(freq)} key={freq.id}>
                                {freq.name}
                            </MenuItem>
                        )}
                    </Select>
                </FormControl>
                <TextField
                    error={vnpError}
                    label={t('voyage_number_pattern')}
                    variant='outlined'
                    value={serviceLineWizard.voyageNumberPattern ?? ''}
                    onChange={onPatternChange}
                />
                <Tooltip title={<div>
                    {t('pattern_info_day')}<br />
                    {t('pattern_info_month')}<br />
                    {t('pattern_info_year')}<br />
                    {t('pattern_info_scn')}<br />
                    {t('pattern_info_uuid')}<br />
                    {t('pattern_info_example')}<br />
                </div>}>
                    <IconButton sx={{ ml: '0px !important' }}>
                        <InfoIcon />
                    </IconButton>
                </Tooltip>
            </Stack>
        </Paper>
    );
}
